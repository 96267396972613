<template>
    <div class="container-fluid">
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ" crossorigin="anonymous">
        <link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/1.12.1/css/jquery.dataTables.css">

        <modal name="change_password" :height="'auto'" :width="'30%'"
               :resizable="true" :draggable="true" :scrollable="true" :reset="true">

            <div class="row justify-content-center">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h5 style="margin-top: 0.5em;">Modifier mon mot de passe</h5>
                        </div>
                        <div class="card-body">
                            <div class="form-group col-md-8 col-md-offset-0 col-xs-10 col-xs-offset-1"
                                 style="margin: auto;">
                                <input id="password" type="password" class="form-control" v-bind:value="password"
                                       placeholder="Mot de passe"
                                       name="password"
                                       required autofocus>
                            </div>

                            <br/>
                            <div class="form-group col-md-8 col-md-offset-1 col-xs-10 col-xs-offset-1"
                                 style="margin: auto;">
                                <input id="password_confirm" type="password" v-bind:value="password_confirm"
                                       class="form-control"
                                       placeholder="Confirmer le mot de passe"
                                       name="password_confirm" required autofocus>
                            </div>

                            <br/>

                            <div class="form-group  col-md-8 col-md-offset-0 col-xs-10 col-xs-offset-1"
                                 style="margin: auto;">

                                <button type="submit" class="btn btn-primary" style="display: block; margin: auto"
                                        @click="changeUserPassword"
                                        :disabled="loading">
                                    <!-- <i class="fa fa-search"></i> -->
                                    Enregistrer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </modal>

        <div class="row justify-content-center">
            <div class="col-md-12" style="display:flex; justify-content: space-between;">

                <div class="card col-md-12">
                  <div class="card-header">
                        <h5 class="text-center" style="margin-top: 0.5em;">Rapport d'activité</h5>
                    </div>
                    <div class="card-body">
                        <div>
                            <ScaleLoader :loading="loading" :color="'#0d6efd'" :size="'2px'"></ScaleLoader>
                        </div>
                        <table ref="myTable" id="my-table" class="table table-striped table-hover table-responsive table-condensed dataTable">
                            <thead>
                            <tr>
                                <th class="text-left">Date</th>
                                <th class="text-right text-md-end pull-right">Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="log in logs">
                                <td class="text-left">{{ formatDate(log.created_at) }}</td>
                                <td class="text-right text-md-end pull-right">{{  log.description }}</td>
                            </tr>
                            </tbody>

                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import 'sweetalert2/dist/sweetalert2.min.css';
import constants from '../constants';
// import {RotatingSpinner} from 'vue-image-spinner';
import {JumpingSpinner} from 'vue-image-spinner';
// import {PulseLoader} from 'vue-spinner/dist/vue-spinner.min';
import {ScaleLoader} from 'vue-spinner/dist/vue-spinner.min';
// import {LinearRotatingSpinner} from 'vue-image-spinner';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import $ from "jquery";
import 'datatables.net-buttons-dt';
import 'datatables.net-responsive';
import 'datatables.net-dt';



export default {
    name: "LogComponent",
    components: {
        vSelect,
        constants,
        // RotatingSpinner
        JumpingSpinner,
        ScaleLoader,
        library,
        faCoffee,
        FontAwesomeIcon,
    },

    // Composition
    mixins: [],

    extends: {},


    data() {
        return {
            logs: [],
            loading: false,
            saving_user: false,
            password: '',
            password_confirm: '',
            user: null,
        }
    },

    props: [],

    computed: {},

    methods: {

      formatDate(value) {
        let created = new Date(value);

        let year = created.getFullYear();
        let month = created.getMonth() + 1;
        let day = created.getDate();

        if(month.toLocaleString().length <= 1){
          month = "0" + month;
        }

        if(day.toLocaleString().length <= 1){
          day = "0" + day;
        }

        return year + "-" + month + "-" + day + ", " + created.toLocaleString('fr-FR', {formatMatcher: 'basic', timeStyle: 'short', 'hour12': true })
      },

        async getLogs(){
            this.loading = true;
            this.logs = [];
            window.axios.get(constants.inassapp_user_log, {
                params: {

                }
            })
                .then(({data}) => {
                    console.log("LOG_RESPONSE", data);
                    if(data.length > 0){
                        this.logs = data;
                    }


                    setTimeout(() =>  $('#my-table').DataTable(), 1000);
                    // $('#my-dable').DataTable();
                    // this.$refs.myTable.DataTable();
                    this.loading = false;

                })
                .catch(error => {
                    this.loading = false;
                    console.log('LOG_ERROR', error.response);
                });
        },
        async getUser() {
            window.axios.get(constants.inassapp_get_user, {
                params: {}
            })
                .then(({data}) => {
                    console.log('USER_DATA', data);
                    if ('user' in data) {
                        this.user = data.user;
                    }
                })
                .catch(error => {
                    console.log('USER_ERROR', error.response);
                });
        },

        changeUserPassword() {
            if (!$('#password').val()) {
                this.$swal({
                    // title: 'Are you sure?',
                    text: 'Veuillez entrer un mot de passe',
                    icon: 'error',
                    type: 'error'
                });

                return;
            }

            if (!$('#password_confirm').val()) {
                this.$swal({
                    // title: 'Are you sure?',
                    text: 'Veuillez confirmer votre mot de passe',
                    icon: 'error',
                    type: 'error'
                });

                return;
            }

            if ($('#password').val() != $('#password_confirm').val()) {
                this.$swal({
                    // title: 'Are you sure?',
                    text: 'Les mots de passe sont différents',
                    icon: 'error',
                    type: 'error'
                });

                return;
            }

            this.$modal.hide('change_password');
            this.loading = true;

            window.axios.post(constants.inassapp_update_password, {
                'user_id': this.user.id,
                'password': $('#password').val()
            })
                .then(({data}) => {
                    console.log("STATUS_RESPONSE", data);
                    this.loading = false;
                    if ('success' in data) {
                        if (data.success) {
                            this.$swal({
                                text: data.message,
                                icon: 'success',
                                type: 'success'
                            });
                        } else {
                            this.$swal({
                                text: data.message,
                                icon: 'error',
                                type: 'error'
                            });
                        }
                    } else {
                        this.$swal({
                            text: "Erreur du serveur",
                            icon: 'error',
                            type: 'error'
                        });
                    }

                })
                .catch(error => {
                    this.loading = false;
                    console.log('STATUS_ERROR', error.response);
                    if (error.response) {
                        console.log('SEARCH_GNN_ERROR_RESPONSE', error.response);
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error',
                            type: 'error'
                        });
                    } else {
                        // this.$swal({
                        //     text: "Aucun client ne correspond 2",
                        //     type: 'error'
                        // });
                    }
                });

        },


        apiLogin() {
            this.loading = true;
            window.axios.get(constants.inassapp_login_url, {
                params: {
                    'username': constants.inassapp_user,
                    'password': constants.inassapp_password,
                }
            })
                .then(({data}) => {
                    console.log("API_RESPONSE", data);
                    this.loading = false;
                    if ('key' in data) {
                        this.api_key = data.key;
                        this.$swal({
                            text: "Test réussi !!",
                            icon: 'success',
                            type: 'success'
                        });
                    } else {
                        this.$swal({
                            text: "Impossible de se connecter a l'API",
                            icon: 'error',
                            type: 'error'
                        });
                    }


                })
                .catch(error => {
                    this.loading = false;
                    console.log('LOGIN_ERROR', error.response);
                    this.$swal({
                        text: "Impossible de se connecter a l'API",
                        icon: 'error',
                        type: 'error'
                    });
                });
        },

    },

    watch: {},

    // Lifecycle Hooks
    beforeCreate() {
    },

    created() {
    },

    beforeMount() {
        this.getLogs();
        this.getUser();
    },

    mounted() {
        // console.log('Component mounted.');
        const that = this;
        $('.pass_change').click(function () {
            that.$modal.show('change_password');
        });

        $('#test_api').click(function () {
            that.apiLogin();
        });
    },

    beforeUpdate() {
    },

    updated() {
    },

    activated() {
    },

    deactivated() {
    },

    beforeDestroy() {
    },

    destroyed() {
    }
}
</script>

<style scoped>

</style>
