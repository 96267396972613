<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">Example Component</div>

                    <div class="card-body">
                        I'm an example component.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        // Composition
        mixins: [],

        extends: {},


        data(){
            return {

            }
        },

        props: [],

        computed: {},

        methods: {},

        watch: {},

        // Lifecycle Hooks
        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
        },

        mounted() {
            console.log('Component mounted.')
        },

        beforeUpdate() {
        },

        updated() {
        },

        activated() {
        },

        deactivated() {
        },

        beforeDestroy() {
        },

        destroyed() {
        }
    }
</script>
