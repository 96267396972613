<template>
  <div class="container-fluid">
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
          integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
          crossorigin="anonymous">

    <modal name="soption" :height="'auto'" :width="'30%'"
           :resizable="true" :draggable="true" :scrollable="true" :reset="true">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5 style="margin-top: 0.5em !important;">Paramètre de recherche</h5>
            </div>
            <div class="card-body">
              <div class="form-check form-switch col-md-11">
                <span>Recherche par Globale Name Number: </span>
                <input class="form-check-input" style="float: right;" type="checkbox"
                       @change="checkSearchOption" v-model="global_name_number">
                <label class="form-check-label" for="flexSwitchCheckDisabled"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal name="change_password" :height="'auto'" :width="'30%'"
           :resizable="true" :draggable="true" :scrollable="true" :reset="true">

      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Modifier mon mot de passe</h5>
            </div>
            <div class="card-body">
              <div class="form-group col-md-8 col-md-offset-0 col-xs-10 col-xs-offset-1"
                   style="margin: auto;">
                <input id="password" type="password" class="form-control" v-bind:value="password"
                       placeholder="Mot de passe"
                       name="password"
                       required autofocus>
              </div>

              <br/>
              <div class="form-group col-md-8 col-md-offset-1 col-xs-10 col-xs-offset-1"
                   style="margin: auto;">
                <input id="password_confirm" type="password" v-bind:value="password_confirm"
                       class="form-control"
                       placeholder="Confirmer le mot de passe"
                       name="password_confirm" required autofocus>
              </div>

              <br/>

              <div class="form-group  col-md-8 col-md-offset-0 col-xs-10 col-xs-offset-1"
                   style="margin: auto;">

                <button type="submit" class="btn btn-primary" style="display: block; margin: auto"
                        @click="changeUserPassword"
                        :disabled="loading">
                  <!-- <i class="fa fa-search"></i> -->
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </modal>

    <div class="row justify-content-center">
      <div class="col-md-12">
<!--          <div class="card">-->
<!--                  <div class="card-body">-->
<!--                     <div class="form-check form-switch">-->
<!--                     <input class="form-check-input" type="checkbox" v-model="gnn">-->
<!--                     <label class="form-check-label" for="flexSwitchCheckDisabled">Recherche par Globale Name Number</label>-->
<!--                 </div>-->
<!--              </div>-->
<!--          </div>-->
        <div class="card">
          <div class="card-header">
            <div class="form-group  col-md-0 col-md-offset-0 col-xs-10 col-xs-offset-1">
              <button type="submit" class="btn btn-secondary" style="margin-top: 0px;"
                      :disabled="loading" @click="$modal.show('soption')">
                <i class="fa fa-cog"></i>
              </button>
            </div>

            <div v-show="!global_name_number"
                 class="form-group col-md-2 col-md-offset-0 col-xs-10 col-xs-offset-1">
              <input id="last_name" type="text" v-model="last_name" class="form-control" placeholder="Nom"
                     name="last_name"
                     required autofocus>
            </div>

            <div v-show="!global_name_number"
                 class="form-group col-md-2 col-md-offset-1 col-xs-10 col-xs-offset-1">
              <input id="first_name" type="text" v-model="first_name" class="form-control"
                     placeholder="Prénom"
                     name="first_name" required autofocus>

            </div>
            <div v-show="!global_name_number" class="form-group col-md-6 col-md-offset-1 col-xs-10 col-xs-offset-1">

              <div class="form-group col-md-12 birth_container" style="margin-top: 2px;">

                <div class="col-md-2" style="margin: auto;">
                  <span>Date de naissance: </span>
                </div>

                <v-select class="col-md-3" style="margin-right: 2px; min-width: 150px;" placeholder="Jour"
                          aria-placeholder="Jour" :hint="'Jour'" :options="days"
                          v-model="selected_day"/>

                <v-select class="col-md-3" style="margin-right: 2px; min-width: 150px;" placeholder="Mois"
                          aria-placeholder="Mois" :hint="'Mois'" :options="months"
                          v-model="selected_month"/>

                <v-select class="col-md-3" label="Année" style="min-width: 150px;" placeholder="Année"
                          aria-placeholder="Année"
                          :int="'Année'" :options="years" v-model="selected_year"/>
              </div>
            </div>

            <div v-show="global_name_number"
                 class="form-group col-md-10 col-md-offset-1 col-xs-10 col-xs-offset-1">

              <div class="form-group col-md-6 global_name_number_container"
                   style="display: block; margin: auto;">

                <input id="global-name-number" type="text" v-model="selected_gnn" style="text-align: center;"
                       class="form-control"
                       placeholder="Globale Name Number"
                       name="g_n_n" required autofocus>
              </div>

            </div>

            <div class="form-group  col-md-1 col-md-offset-0 col-xs-10 col-xs-offset-1">

              <button type="submit" class="btn btn-primary" style="margin-top: 0px;" @click="checkInputs"
                      :disabled="loading">
                <!-- <i class="fa fa-search"></i> -->
                Rechercher
              </button>
            </div>

          </div>

          <!--                    <JumpingSpinner src="img/nl_bg_removed.png" v-show="loading" :width="'200px'"/>-->
          <div>
            <ScaleLoader :loading="loading" :color="'#0d6efd'" :size="'4px'" style="margin-top: 20px;"></ScaleLoader>
          </div>


          <div class="card-body" v-show="clients.length > 0" style="margin-top: 15px;">
            <div class="table-container table-responsive">
              <p>Résultat Recherche Client: {{ this.result_date.toLocaleString() }} </p>
              <table class="table table-responsive table-striped table-hover" style="overflow-x: auto;">
                <thead>
                <tr>
                  <th class="text-left">Identification</th>
                  <th class="text-center">Prénom</th>
                  <th class="text-center">Nom</th>
                  <th class="text-center">DDN (JJ/MM/AAAA)</th>
                  <th class="text-center">Adresse</th>
                  <th class="text-center">Numéro de police hérité</th>
                  <th class="text-center">Institution</th>
                  <th class="text-center">Est un dépendant</th>
                  <th class="text-center">Assuré Principal</th>
                  <th class="text-center">Hero</th>
                  <th class="text-center">Statut</th>
                  <th class="text-center"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="client in clients" v-bind:class="'status_' + client.status">
                  <td class="text-left">{{ client.employee_id }}</td>
                  <td class="text-center">{{ client.first_name }}</td>
                  <td class="text-center">{{ client.last_name }}</td>
                  <td class="text-center">{{ transformdate(client.dob) }}</td>
                  <td class="text-center">{{ client.address }}</td>
                  <td class="text-center">{{ client.legacy_policy_number }}</td>
                  <td class="text-center">{{ client.company }}</td>
                  <td class="text-center" v-if="client.employee_id != client.primary_employee_id">
                    OUI
                  </td>
                  <td class="text-center" v-else> NON</td>
                  <td class="text-center" v-if="client.employee_id != client.primary_employee_id">
                    {{ client.primary_employee_id + ' : ' + client.primary_name }}
                  </td>
                  <td class="text-center" v-else> --</td>
                  <td class="text-center">
                    <PulseLoader :loading="!client.benefits" :color="'#0d6efd'"
                                 :size="'4px'"></PulseLoader>
                    <span v-show="client.benefits">{{ client.hero_tag }}</span>
                  </td>
                  <td class="text-center" v-if="client.status"><span class="badge badge-success"
                                                                     style="background: green">Actif</span>
                  </td>
                  <td class="text-center" v-else><span class="badge badge-danger"
                                                       style="background: red;">Inactif</span></td>
                  <td class="text-center">
                    <button class="btn btn-primary" :disabled="!client.benefits"
                            @click="showModal(client)">
                      <i class="fa fa-eye"></i>

                    </button>
                  </td>
                  <modal :name="client.employee_id.toString()" :height="'auto'" :width="'60%'"
                         :resizable="true" :draggable="true" :scrollable="true" :reset="true">
                    <div class="row justify-content-center">
                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-header" style="padding-top: 1em;">
                            <h2>Recherche active</h2>
                            <ul class="navbar-nav ms-auto"
                                style="width: 45%; display: flex; flex-direction: row; justify-content: flex-end;">
                              <li class="nav-item" style="margin-right: 20px;">
                                <button class="btn  btn-primary"
                                        @click="printPDF(client)"><i
                                    class="fa fa-print"></i> Imprimer
                                </button>
                              </li>
                              <li class="nav-item">
                                <button class="btn btn-success"
                                        @click="sendMail(client)"><i
                                    class="fa fa-envelope"></i> Envoyer par email
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div class="card-body">
                            <table class="table table-striped">
                              <tbody>
                              <tr>
                                <td class="font-weight-bold" style="font-weight: bold;">Client</td>
                                <td class="text-right text-md-end pull-right">{{
                                    client.first_name + " " + client.last_name
                                  }}
                                </td>
                              </tr>

                              <tr>
                                <td class="font-weight-bold" style="font-weight: bold;">Identification</td>

                                <td class="text-right text-md-end pull-right">{{ client.employee_id }}</td>
                              </tr>


                              <tr>
                                <td class="font-weight-bold" style="font-weight: bold;">Institution</td>

                                <td class="text-right text-md-end pull-right">{{ client.company }}</td>
                              </tr>


                              <tr>
                                <td class="font-weight-bold" style="font-weight: bold;">Numéro de police hérité</td>

                                <td class="text-right text-md-end pull-right"> {{ client.legacy_policy_number }}</td>
                              </tr>


                              <tr>
                                <td class="font-weight-bold" style="font-weight: bold;">Date de Naissance</td>

                                <td class="text-right text-md-end pull-right"> {{ transformdate(client.dob) }}</td>
                              </tr>

                              <tr>
                                <td class="font-weight-bold" style="font-weight: bold;">Hero</td>

                                <td class="text-right text-md-end pull-right"> {{ client.hero_tag }}</td>
                              </tr>

                              <tr>
                                <td class="font-weight-bold" style="font-weight: bold;">Dépendant</td>

                                <td class="text-right text-md-end pull-right">
                                                                    <span
                                                                        v-if="client.employee_id != client.primary_employee_id">{{
                                                                        client.primary_employee_id + ' : ' + client.primary_name
                                                                      }}</span>
                                  <span v-else>NON</span>
                                </td>
                              </tr>

                              <tr>
                                <td class="font-weight-bold" style="font-weight: bold;">Statut</td>
                                <td class="text-right text-md-end pull-right">
                                  <button class="btn btn-success" v-if="client.status">Client actif</button>
                                  <button class="btn btn-danger" v-else>Client inactif</button>
                                </td>
                              </tr>

                              </tbody>
                            </table>
                            <!--                                                        <p>Policies</p>-->
                            <table v-if="user != null && (user.role == 'Admin' || user.role == 'Simple utilisateur')" class="table" table-striped>
                              <thead>
                              <tr>
                                <th class="text-left">Ext</th>
                                <th class="text-center">Nom</th>
                                <th class="text-center">Limit</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="extension in client.policy_extensions">
                                <td class="text-left">{{ extension.code }}</td>
                                <td class="text-center">{{ extension.name_for_display }}</td>
                                <td class="text-center">{{ formatPrice(extension.limit) }}</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="card-footer">
                            <ul class="navbar-nav ms-auto"
                                style="width: 45%; display: flex; flex-direction: row; justify-content: flex-end;">
                              <li class="nav-item">
                                <button class="btn btn-secondary"
                                        @click="$modal.hide(client.employee_id.toString())"><i
                                    class="fa fa-times"></i> Fermer
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </modal>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import 'sweetalert2/dist/sweetalert2.min.css';
import constants from '../constants';
// import {RotatingSpinner} from 'vue-image-spinner';
import {JumpingSpinner} from 'vue-image-spinner';
import {PulseLoader} from 'vue-spinner/dist/vue-spinner.min';
import {ScaleLoader} from 'vue-spinner/dist/vue-spinner.min';
import VueHtml2pdf from "vue-html2pdf";
// import {VueHtml2Pdf} from 'vue-html2pdf';
// import {ScaleLoader} from 'vue-spinner/dist/vue-spinner.min';
// import {LinearRotatingSpinner} from 'vue-image-spinner';


export default {
  name: "SearchComponent",
  components: {
    vSelect,
    constants,
    // RotatingSpinner
    JumpingSpinner,
    PulseLoader,
    ScaleLoader,
    // VueHtml2Pdf,
  },

  // Composition
  mixins: [],

  extends: {},


  data() {
    return {
      days: [],
      months: [
        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
      ],
      years: [],
      selected_day: '',
      selected_month: '',
      selected_year: '',
      first_name: '',
      last_name: '',
      selected_gnn: '',
      global_name_number: false,
      api_key: '',
      log_id: '',
      user: null,
      clients: [],
      result_date: [],
      loading: false,
      password: '',
      password_confirm: '',
    }
  },

  props: [],

  computed: {},

  methods: {

    transformdate(value){
      var dateObject = new Date(value);

      let day =  dateObject.getDate();
      let month = (dateObject.getMonth() + 1);

      if(day.toString().length < 2){
        day = "0" + day.toString();
      }

      if(month.toString().length < 2){
        month = "0" + month.toString();
      }

      return day + "/" + month + "/" + dateObject.getFullYear();
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    async calculateData() {
      for (let i = 1; i <= 31; i++) {

        if (i < 10) {
          this.days.push("0" + i);
        } else {
          this.days.push(i);
        }
      }

      let y = new Date().getFullYear();
      for (let i = y; i >= y - 150; i--) {
        this.years.push(i);
      }
      // console.log('DATAS', "FONC");
      // console.log('days', this.days);
      // console.log('years', this.years);
    },

    checkSearchOption() {
      // console.log('SEARCH_OPTION', this.global_name_number);
      this.selected_gnn = '';
      this.first_name = '';
      this.last_name = '';
      this.selected_day = '';
      this.selected_month = '';
      this.selected_year = '';
    },

    showModal(client) {
      // this.$refs["mod-2"].show();
      // this.$modal.show('modal-1');
      this.$modal.show(client.employee_id.toString());
      this.saveHistory(client);
    },

    printPDF(client) {
      // (this.$refs[client.employee_id.toString()] as VueHtml2pdf).generatePdf();
      // console.log("DEBUG_CLIENT", this.$refs['' + client.employee_id.toString()]);
      // this.$refs['' + client.employee_id.toString()][0].generatePdf();
      this.generatePdf(client);
    },

    async saveHistory(client) {
      window.axios.post(constants.inassapp_save_history,
          {
            'employee_id': client.employee_id,
            'first_name': client.first_name,
            'last_name': client.last_name,
            'dob': client.dob,
            'legacy_policy_number': client.legacy_policy_number,
            'company': client.company,
            'primary_employee_id': client.primary_employee_id,
            'hero_tag': client.hero_tag,
            'status': client.status,
            'primary_employee_name': (client.employee_id != client.primary_employee_id) ? client.primary_employee_id + ' : ' + client.primary_name : 'N/A',
          })
          .then(({data}) => {
            // console.log("SAVE_HISTORY_RESPONSE", data);

            if ('success' in data) {
              if (data.success) {
                // client.log_id = data.log_id;
                // client.user = data.user;
                this.getUser();
                // console.log('NEW_CLIENT', client);
              }

            }
          })
          .catch(error => {
            // this.loading = false;
            console.log('SAVE_HISTORY_RESPONSE', error.response);
          });
    },

    sendMail(client) {
      this.$modal.hide(client.employee_id.toString());
      this.loading = true;
      window.axios.post(constants.inassapp_send_email,
          {
            'client': client,
            'log_id': this.log_id,
          })
          .then(({data}) => {
            // console.log("MAIL_RESPONSE", data);
            this.loading = false;
            if ('success' in data) {
              if (data.success) {
                this.$swal({
                  text: data.message,
                  icon: 'success',
                  type: 'success'
                });
              } else {
                this.$swal({
                  text: data.message,
                  icon: 'error',
                  type: 'error'
                });
              }
            } else {
              this.$swal({
                text: "Une erreur c'est produite !",
                icon: 'error',
                type: 'error'
              });
            }
          })
          .catch(error => {
            this.loading = false;
            if (error.response) {
              if ('data' in error.response) {
                this.$swal({
                  text: error.response.data.message,
                  icon: 'error',
                  type: 'error'
                });
              }
            }
            console.log('MAIL_RESPONSE', error.response);

          });
    },

    generatePdf(client) {
      this.$modal.hide(client.employee_id.toString());
      this.loading = true;
      window.axios.post(constants.inassapp_generate_pdf,
          {
            'client': client,
            'log_id': this.log_id,
          })
          .then(({data}) => {
            // console.log("PDF_RESPONSE", data);
            this.loading = false;
            if ('success' in data) {
              if (data.success) {
                this.$swal({
                  text: "Document prêt !",
                  icon: 'success',
                  type: 'success'
                });

                window.open(data.message, '_blank');
              } else {
                this.$swal({
                  text: data.message,
                  icon: 'error',
                  type: 'error'
                });
              }
            } else {
              this.$swal({
                text: "Une erreur c'est produite !",
                icon: 'error',
                type: 'error'
              });
            }
          })
          .catch(error => {
            this.loading = false;
            if (error.response) {
              if ('data' in error.response) {
                this.$swal({
                  text: error.response.data.message,
                  icon: 'error',
                  type: 'error'
                });
              }
            }
            console.log('MAIL_RESPONSE', error.response);

          });
    },


    async customerBenefit(client) {
      window.axios.get(constants.inassapp_benefit_url, {
        params: {
          'first_name': client.first_name,
          'employee_id': client.employee_id,
          'key': this.api_key
        }
      })
          .then(({data}) => {
            // console.log("BENEFIT_RESPONSE", data);
            client.benefits = true;
            if ('success' in data) {
              if (data.success) {
                if (data.policy_extensions.length > 0) {
                  client.policy_extensions = data.policy_extensions;
                  client.policy_extensions.forEach(function (policy) {
                    if (policy.extension.includes("HERO")) {
                      client.hero_tag = policy.name_for_display;
                    }
                  });
                }
              } else {
              }
            } else {
            }
          })
          .catch(error => {
            client.benefits = true;
            console.log('BENEFIT_ERROR', error);
          });
    },

    async getUser() {
      window.axios.get(constants.inassapp_get_user, {
        params: {}
      })
          .then(({data}) => {
            // console.log('USER_DATA', data);
            if ('user' in data) {
              this.user = data.user;
            }

            if ('log_id' in data) {
              this.log_id = data.log_id + 2000;
            }
          })
          .catch(error => {
            console.log('USER_ERROR', error.response);
          });
    },

    customerSearch() {
      const that = this;

      window.axios.get(constants.inassapp_search_url, {
        params: {
          'first_name': this.first_name,
          'last_name': this.last_name,
          'dob': (this.months.indexOf(this.selected_month) + 1) + "/" + this.selected_day + "/" + this.selected_year,
          'key': this.api_key
        }
      })
          .then(({data}) => {
            // console.log("SEARCH_RESPONSE", data);
            this.loading = false;
            if ('clients' in data) {
              if (data.clients.length > 0) {

                data.clients.forEach(function (client) {
                  client.benefits = false;
                  client.hero_tag = 'N/A';
                  that.clients.push(client);
                });
                // this.clients = data.clients;
                this.result_date = new Date();
                this.clients.forEach(function (client) {
                  that.customerBenefit(client);
                });
              } else {
                this.$swal({
                  text: "Aucun client ne correspond a cette recherche",
                  icon: 'error',
                  type: 'error'
                });
              }
            } else {
              this.$swal({
                text: data.message,
                icon: 'error',
                type: 'error'
              });
            }

          })
          .catch(error => {
            console.log('SEARCH_ERROR', error);
            this.loading = false;
            if (error.response) {
              console.log('SEARCH_ERROR_RESPONSE', error.response);
              this.$swal({
                text: error.response.data.message,
                icon: 'error',
                type: 'error'
              });
            } else {
              // this.$swal({
              //     text: "Aucun client ne correspond 2",
              //     type: 'error'
              // });
            }
          });
    },

    changeUserPassword() {

      // console.log("pass_1", $('#password').val());
      // console.log("pass_2", $('#password_confirm').val());

      if (!$('#password').val()) {
        this.$swal({
          // title: 'Are you sure?',
          text: 'Veuillez entrer un mot de passe',
          icon: 'error',
          type: 'error'
        });

        return;
      }

      if (!$('#password_confirm').val()) {
        this.$swal({
          // title: 'Are you sure?',
          text: 'Veuillez confirmer votre mot de passe',
          icon: 'error',
          type: 'error'
        });

        return;
      }

      if ($('#password').val() != $('#password_confirm').val()) {
        // console.log("pass1", password);
        // console.log("pass2", password_confirm);
        this.$swal({
          // title: 'Are you sure?',
          text: 'Les mots de passe sont différents',
          icon: 'error',
          type: 'error'
        });

        return;
      }

      this.$modal.hide('change_password');
      this.loading = true;

      window.axios.post(constants.inassapp_update_password, {'user_id': this.user.id, 'password': $('#password').val()})
          .then(({data}) => {
            // console.log("STATUS_RESPONSE", data);
            this.loading = false;
            if ('success' in data) {
              if (data.success) {
                this.$swal({
                  text: data.message,
                  icon: 'success',
                  type: 'success'
                });
              } else {
                this.$swal({
                  text: data.message,
                  icon: 'error',
                  type: 'error'
                });
              }
            } else {
              this.$swal({
                text: "Erreur du serveur",
                icon: 'error',
                type: 'error'
              });
            }

          })
          .catch(error => {
            this.loading = false;
            console.log('STATUS_ERROR', error.response);
            if (error.response) {
              console.log('SEARCH_GNN_ERROR_RESPONSE', error.response);
              this.$swal({
                text: error.response.data.message,
                icon: 'error',
                type: 'error'
              });
            } else {
              // this.$swal({
              //     text: "Aucun client ne correspond 2",
              //     type: 'error'
              // });
            }
          });

    },


    customerSearchByNumber() {
      const that = this;

      window.axios.get(constants.inassapp_search_by_number_url, {
        params: {
          'globale_name_number': this.selected_gnn,
          'key': this.api_key
        }
      })
          .then(({data}) => {
            console.log("SEARCH_GNN_RESPONSE", data);
            this.loading = false;
            if ('clients' in data) {
              if (data.clients.length > 0) {

                data.clients.forEach(function (client) {
                  client.benefits = false;
                  client.hero_tag = 'N/A';
                  that.clients.push(client);
                });
                // this.clients = data.clients;
                this.result_date = new Date();
                this.clients.forEach(function (client) {
                  that.customerBenefit(client);
                });
              } else {
                this.$swal({
                  text: "Aucun client ne correspond a cette recherche",
                  icon: 'error',
                  type: 'error'
                });
              }
            } else {
              this.$swal({
                text: data.message,
                icon: 'error',
                type: 'error'
              });
            }

          })
          .catch(error => {
            console.log('SEARCH_ERROR', error);
            this.loading = false;
            if (error.response) {
              console.log('SEARCH_GNN_ERROR_RESPONSE', error.response);
              this.$swal({
                text: error.response.data.message,
                icon: 'error',
                type: 'error'
              });
            } else {
              // this.$swal({
              //     text: "Aucun client ne correspond 2",
              //     type: 'error'
              // });
            }
          });
    },

    apiLogin(isApiTest) {
      this.loading = true;
      window.axios.get(constants.inassapp_login_url, {
        params: {
          'username': constants.inassapp_user,
          'password': constants.inassapp_password,
        }
      })
          .then(({data}) => {
            // console.log("API_RESPONSE", data);

            if (isApiTest) {
              this.loading = false;
              if ('key' in data) {
                this.api_key = data.key;
                this.$swal({
                  text: "Test réussi !!",
                  icon: 'success',
                  type: 'success'
                });
              } else {
                this.$swal({
                  text: "Impossible de se connecter a l'API",
                  icon: 'error',
                  type: 'error'
                });
              }
            } else {
              if ('key' in data) {
                this.api_key = data.key;
                if (this.global_name_number) {
                  this.customerSearchByNumber();
                } else {
                  this.customerSearch();
                }

              } else {
                this.loading = false;
                this.$swal({
                  text: data.message,
                  icon: 'error',
                  type: 'error'
                });
              }
            }

          })
          .catch(error => {
            this.loading = false;
            console.log('LOGIN_ERROR', error.response);
            if (isApiTest) {
              this.$swal({
                text: "Impossible de se connecter a l'API",
                icon: 'error',
                type: 'error'
              });
            } else {
              if (error.response) {
                this.$swal({
                  text: error.response.message,
                  icon: 'error',
                  type: 'error'
                });
              } else {
                this.$swal({
                  text: "Impossible de communiquer avec l'API",
                  icon: 'error',
                  type: 'error'
                });
              }
            }

          });


    },

    checkInputs() {


      if (this.global_name_number) {

        if (!this.selected_gnn) {
          this.$swal({
            // title: 'Are you sure?',
            text: 'Veuillez presicer le Globale Name Number',
            icon: 'error',
            type: 'error'
          });

          return;
        }

        if (isNaN(this.selected_gnn)) {
          this.$swal({
            // title: 'Are you sure?',
            text: 'le Globale Name Number doit etre un nombre valide',
            icon: 'error',
            type: 'error'
          });

          return;
        }

      } else {


        if (!this.first_name) {
          this.$swal({
            // title: 'Are you sure?',
            text: "Veuillez presicer le prénom de l'assuré",
            icon: 'error',
            type: 'error'
          });

          return;
        }

        if (!this.last_name) {
          this.$swal({
            // title: 'Are you sure?',
            text: 'Veuillez presicer le nom de l\'assuré',
            icon: 'error',
            type: 'error'
          });

          return;
        }


        if (!this.selected_day) {
          this.$swal({
            // title: 'Are you sure?',
            text: 'Veuillez presicer le jour de naissance de l\'assuré',
            icon: 'error',
            type: 'error'
          });

          return;
        }

        if (!this.selected_month) {
          this.$swal({
            // title: 'Are you sure?',
            text: 'Veuillez preciser le mois de naissance de l\'assuré',
            icon: 'error',
            type: 'error'
          });

          return;
        }

        if (!this.selected_year) {
          this.$swal({
            // title: 'Are you sure?',
            text: 'Veuillez preciser l\'année de naissance de l\'assuré',
            icon: 'error',
            type: 'error'
          });
          return;
        }
      }

      // console.log('data', this.selected_day + " " + this.selected_month + " " + this.selected_year);

      this.clients = [];
      // this.getUser();
      this.apiLogin(false);
    },


  },

  watch: {},

  // Lifecycle Hooks
  beforeCreate() {
  },

  created() {
  },

  beforeMount() {
    this.calculateData();
    this.getUser();
  },

  mounted() {
    // console.log('Component mounted.');
    // console.log("ACTIOn_CHANGE_PASS", $('.pass_change'));

    const that = this;
    $('.pass_change').click(function () {
      that.$modal.show('change_password');
    });

    $('#test_api').click(function () {
      that.apiLogin(true);
    });

  },

  beforeUpdate() {
  },

  updated() {
  },

  activated() {
  },

  deactivated() {
  },

  beforeDestroy() {
  },

  destroyed() {
  }
}
</script>

<style scoped>

.status_0 {
  background: #f2dede !important;
  color: red;
}

.pdf_main {
  font-family: Verdana, Arial, sans-serif;
}

.pdf_main li {
  width: 100%;

}

#result td {
  min-width: 40%;
}

#result tr td:nth-child(2) {
  padding-left: 20px;
}

#date-container {
  text-align: right;
}


.pdf_main .header {
  font-size: 1em;
  /*background: rgb(6, 93, 170);*/
  background: rgb(37, 41, 74);
  /*border-radius: 5px 5px 5px 5px;*/
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 15px;
}

.pdf_main tfoot tr td {
  font-weight: bold;
  font-size: x-small;
}

.pdf_main .gray {
  background-color: lightgray;
}


.pdf_main tr {
  padding-left: 20px;
}

.results_container div {
  baground-color: rgb(255, 255, 255);
  margin-top: 10px;
}

.results_container {
  padding-left: 10px;
}

.pdf_main .img_container {
  width: 150px;
  height: 150px;
}

.pdf_main .img_container img {
  float: center;
}

</style>
