<template>
  <div class="container-fluid">
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
          integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
          crossorigin="anonymous">
    <link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/1.12.1/css/jquery.dataTables.css">

    <modal name="change_password" :height="'auto'" :width="'30%'"
           :resizable="true" :draggable="true" :scrollable="true" :reset="true">

      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5 style="margin-top: 0.5em;">Modifier mon mot de passe</h5>
            </div>
            <div class="card-body">
              <div class="form-group col-md-8 col-md-offset-0 col-xs-10 col-xs-offset-1"
                   style="margin: auto;">
                <input id="password" type="password" class="form-control" v-bind:value="password"
                       placeholder="Mot de passe"
                       name="password"
                       required autofocus>
              </div>

              <br/>
              <div class="form-group col-md-8 col-md-offset-1 col-xs-10 col-xs-offset-1"
                   style="margin: auto;">
                <input id="password_confirm" type="password" v-bind:value="password_confirm"
                       class="form-control"
                       placeholder="Confirmer le mot de passe"
                       name="password_confirm" required autofocus>
              </div>

              <br/>

              <div class="form-group  col-md-8 col-md-offset-0 col-xs-10 col-xs-offset-1"
                   style="margin: auto;">

                <button type="submit" class="btn btn-primary" style="display: block; margin: auto"
                        @click="changeUserPassword"
                        :disabled="loading">
                  <!-- <i class="fa fa-search"></i> -->
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </modal>

    <modal name="add_user" :height="'auto'" :width="'30%'"
           :resizable="true" :draggable="true" :scrollable="true" :reset="true">
      <div class="row justify-content-center">
        <div class="card col-md-12">
          <div class="card-header">
            <h3 style="text-align: start; width: 100%;">Ajouter un nouvel utilisateur</h3>
          </div>

          <div class="card-body">

            <div class="form-group col-md-12 col-md-offset-0 col-xs-10 col-xs-offset-1">
              <input id="last_name" type="text" style="min-width: 150px;" v-model="last_name"
                     class="form-control" placeholder="Nom"
                     name="last_name"
                     required autofocus>

            </div>
            <br/>

            <div class="form-group col-md-12 col-md-offset-1 col-xs-10 col-xs-offset-1">
              <input id="first_name" type="text" style="min-width: 150px;" v-model="first_name"
                     class="form-control"
                     placeholder="Prénom"
                     name="first_name" required autofocus>

            </div>

            <br/>

            <div class="form-group col-md-12 col-md-offset-1 col-xs-10 col-xs-offset-1">
              <input id="username" type="text" style="min-width: 150px;" v-model="user_name"
                     class="form-control"
                     placeholder="Nom d'utilisateur"
                     name="user_name" required autofocus>

            </div>

            <br/>

            <div class="form-group col-md-12 col-md-offset-1 col-xs-10 col-xs-offset-1">
              <input id="email" type="text" style="min-width: 150px;" v-model="email" class="form-control"
                     placeholder="E-mail"
                     name="email" required autofocus>

            </div>

            <br/>

            <!--                        <div class="form-group col-md-12 col-md-offset-1 col-xs-10 col-xs-offset-1">-->
            <!--                            <input id="email" type="text" v-model="institution" class="form-control"-->
            <!--                                   placeholder="Institution"-->
            <!--                                   name="institution" required autofocus>-->
            <!--                        </div>-->

            <div class="form-group col-md-12 col-md-offset-1 col-xs-10 col-xs-offset-1">
              <v-select placeholder="Institution"
                        aria-placeholder="Institution" style="min-width: 200px;" :hint="'Institution'"
                        :options="institutions"
                        v-model="institution"/>
            </div>

            <br/>

            <div class="form-group col-md-12 col-md-offset-1 col-xs-10 col-xs-offset-1">

              <v-select class="col-md-12" label="Accès" style="min-width: 150px;" placeholder="Accès"
                        aria-placeholder="Accès"
                        :int="'Accès'" :options="roles" v-model="selected_role"/>
            </div>

            <br/>

            <div class="form-group col-md-12 text-center col-md-offset-1 col-xs-10 col-xs-offset-1">
              <button type="submit" class="btn btn-primary" style="margin-top: 4px;" @click="checkInputs"
                      :disabled="loading">
                <span class="glyphicon glyphicon-search"></span>
                Enregistrer
              </button>
            </div>


            <br/>

            <div>
              <ScaleLoader :loading="saving_user" :color="'#0d6efd'" :size="'4px'"></ScaleLoader>
            </div>

          </div>
        </div>
      </div>
    </modal>


    <div class="row justify-content-center">
      <div class="col-md-12" style="display:flex; justify-content: space-between;">
        <div class="card col-md-12">
          <div class="card-header">
            <h5 style="margin-top: 0.5em;">Utilisateurs</h5>

            <ul class="navbar-nav ms-auto"
                style="width: 45%; display: flex; flex-direction: row; justify-content: flex-end;">
              <li class="nav-item" style="margin-right: 5px;">
                <button class="btn  btn-primary" @click="$modal.show('add_user')"><i
                    class="fa fa-plus"></i>
                </button>
              </li>
            </ul>
          </div>


          <div class="card-body table-responsive">
            <div>
              <ScaleLoader :loading="loading" :color="'#0d6efd'" :size="'2px'"></ScaleLoader>
            </div>
            <table ref="myTable" id="my-table"
                   class="table table-striped table-responsive table-hover table-condensed dataTable">
              <thead>
              <tr>
                <th class="text-left">Nom</th>
                <th class="text-center">Email</th>
                <th class="text-center">Nom d'utilisateur</th>
                <th class="text-center">Institution</th>
                <th class="text-center">Accès</th>
                <th class="text-center">Statut</th>
                <th class="text-center">Réinitialiser</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="user in users">
                <td class="text-left">{{ user.first_name + ' ' + user.last_name }}</td>
                <td class="text-center">{{ user.email_address }}</td>
                <td class="text-center">{{ user.email.substr(0, user.email.indexOf('@')) }}</td>
                <td class="text-center">{{ user.institution }}</td>
                <td class="text-center">{{ user.role }}</td>
                <td class="text-center">
                  <div class="form-check form-switch" style="display: inline-block; margin: auto;">
                    <!-- <input class="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled> -->
                    <input class="form-check-input" type="checkbox" @change="changeUserStatus(user)"
                           v-model="user.status">
                    <label class="form-check-label" for="flexSwitchCheckDisabled"></label>
                  </div>
                </td>
                <td class="text-center">
                  <button type="submit" class="btn btn-primary" @click="resetPassword(user.id)"
                          :disabled="loading">
                    <Icon icon="mdi:refresh" />
                  </button>
                </td>
              </tr>
              </tbody>

            </table>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import 'sweetalert2/dist/sweetalert2.min.css';
import constants from '../constants';
// import {RotatingSpinner} from 'vue-image-spinner';
import {JumpingSpinner} from 'vue-image-spinner';
// import {PulseLoader} from 'vue-spinner/dist/vue-spinner.min';
import {ScaleLoader} from 'vue-spinner/dist/vue-spinner.min';
// import {LinearRotatingSpinner} from 'vue-image-spinner';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCoffee} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {Icon} from '@iconify/vue2';
import $ from "jquery";
import 'datatables.net-buttons-dt';
import 'datatables.net-responsive';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css'


export default {
  name: "SettingComponent",
  components: {
    vSelect,
    constants,
    // RotatingSpinner
    JumpingSpinner,
    ScaleLoader,
    library,
    faCoffee,
    FontAwesomeIcon,
    Icon,
  },

  // Composition
  mixins: [],

  extends: {},


  data() {
    return {
      users: [],
      institutions: [],
      roles: [
        'Medecin', 'Admin', 'Simple utilisateur'
      ],

      selected_role: '',
      first_name: '',
      last_name: '',
      user_name: '',
      institution: '',
      password: '',
      password_confirm: '',
      email: '',
      api_key: '',
      user: null,
      loading: false,
      loader: false,
      saving_user: false,
      dt: null,
    }
  },

  props: [],

  computed: {},

  methods: {


    async getUsers() {
      this.loading = true;
      this.users = [];
      const that = this;

      window.axios.get(constants.inassapp_users, {
        params: {}
      })
          .then(({data}) => {
            console.log("USERS_RESPONSE", data);
            this.users = [];

            if (data.length > 0) {
              this.users = data;
            }


            try {

              if (that.dt != null) {
                // this.dt.destroy();
                that.dt.DataTable().rows().remove();
                that.dt.DataTable().clear();
                that.dt.DataTable().ajax.reload();
                // that.dt.DataTable().draw();
              }

              setTimeout(function () {
                that.dt = $('#my-table').DataTable({
                  paging: true,
                  ordering: true,
                  info: true,
                });
              }, 1500);

            } catch (erro) {

            }

            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            console.log('USERS_ERROR', error);
          });
    },

    openModal() {
      this.$modal.show('add_user');
    },


    changeUserStatus(user) {

      user.status = !user.status;

      console.log("U", user);

      // this.loading = true;

      window.axios.post(constants.inassapp_user_status, {'user_id': user.id, 'status': user.status})
          .then(({data}) => {
            console.log("STATUS_RESPONSE", data);
            // this.loading = false;
            if ('success' in data) {
              if (!data.success) {
                user.status = !user.status;
              }
            } else {
              user.status = !user.status;
            }

          })
          .catch(error => {
            // this.loading = false;
            user.status = !user.status;
            console.log('STATUS_ERROR', error.response);
          });

    },

    resetPassword(userId) {
      this.loading = true;
      window.axios.post(constants.inassapp_reset_password,
          {
            'user_id': userId,
          }
      )
          .then(({data}) => {
            this.loading = false;
            if ('success' in data) {
              if (data.success) {
                this.$swal({
                  text: data.message,
                  icon: 'success',
                  type: 'success'
                });
              } else {
                this.$swal({
                  text: data.message,
                  icon: 'error',
                  type: 'error'
                });
              }
            } else {
              this.$swal({
                text: data.message,
                icon: 'error',
                type: 'error'
              });
            }

          })
          .catch(error => {
            this.loading = false;
            console.log('STATUS_ERROR', error.response);
            this.$swal({
              text: error.response.data.message,
              icon: 'error',
              type: 'error'
            });

          });
    },

    saveUser() {
      this.saving_user = true;
      window.axios.post(constants.inassapp_users,
          {
            'first_name': this.first_name,
            'last_name': this.last_name,
            'user_name': this.user_name,
            'role': this.selected_role,
            'email': this.email,
            'institution': this.institution,
          }
      )
          .then(({data}) => {
            // console.log("STATUS_RESPONSE", data);
            // this.loading = false;
            this.$modal.hide('add_user');
            this.saving_user = false;
            if ('success' in data) {
              if (data.success) {
                this.getUsers();
                this.first_name = '';
                this.last_name = '';
                this.user_name = '';
                this.email = '';
                this.institution = '';
                this.selected_role = '';

                this.$swal({
                  text: data.message,
                  icon: 'success',
                  type: 'success'
                });
              } else {
                this.$swal({
                  text: data.message,
                  icon: 'error',
                  type: 'error'
                });
              }
            } else {
              this.$swal({
                text: data.message,
                icon: 'error',
                type: 'error'
              });
            }

          })
          .catch(error => {
            this.saving_user = false;
            this.$modal.hide('add_user');
            console.log('STATUS_ERROR', error.response);
            this.$swal({
              text: error.response.data.message,
              icon: 'error',
              type: 'error'
            });

          });

    },

    async getUser() {
      window.axios.get(constants.inassapp_get_user, {
        params: {}
      })
          .then(({data}) => {
            console.log('USER_DATA', data);
            if ('user' in data) {
              this.user = data.user;
            }
          })
          .catch(error => {
            console.log('USER_ERROR', error.response);
          });
    },

    async getInstitutions() {
      window.axios.get(constants.inassapp_institutions, {
        params: {}
      })
          .then(({data}) => {
            console.log('INST_DATA', data);
            this.institutions = data;
          })
          .catch(error => {
            console.log('INST_DATA', error.response);
          });
    },

    changeUserPassword() {
      if (!$('#password').val()) {
        this.$swal({
          // title: 'Are you sure?',
          text: 'Veuillez entrer un mot de passe',
          icon: 'error',
          type: 'error'
        });

        return;
      }

      if (!$('#password_confirm').val()) {
        this.$swal({
          // title: 'Are you sure?',
          text: 'Veuillez confirmer votre mot de passe',
          icon: 'error',
          type: 'error'
        });

        return;
      }

      if ($('#password').val() != $('#password_confirm').val()) {
        this.$swal({
          // title: 'Are you sure?',
          text: 'Les mots de passe sont différents',
          icon: 'error',
          type: 'error'
        });

        return;
      }

      this.$modal.hide('change_password');
      this.loading = true;

      window.axios.post(constants.inassapp_update_password, {
        'user_id': this.user.id,
        'password': $('#password').val()
      })
          .then(({data}) => {
            console.log("STATUS_RESPONSE", data);
            this.loading = false;
            if ('success' in data) {
              if (data.success) {
                this.$swal({
                  text: data.message,
                  icon: 'success',
                  type: 'success'
                });
              } else {
                this.$swal({
                  text: data.message,
                  icon: 'error',
                  type: 'error'
                });
              }
            } else {
              this.$swal({
                text: "Erreur du serveur",
                icon: 'error',
                type: 'error'
              });
            }

          })
          .catch(error => {
            this.loading = false;
            console.log('STATUS_ERROR', error.response);
            if (error.response) {
              console.log('SEARCH_GNN_ERROR_RESPONSE', error.response);
              this.$swal({
                text: error.response.data.message,
                icon: 'error',
                type: 'error'
              });
            } else {
              // this.$swal({
              //     text: "Aucun client ne correspond 2",
              //     type: 'error'
              // });
            }
          });

    },

    checkInputs() {

      if (!this.last_name) {
        this.$swal({
          // title: 'Are you sure?',
          text: 'Le champs nom est obligatoire',
          icon: 'error',
          type: 'error'
        });

        return;
      }


      if (!this.first_name) {
        this.$swal({
          // title: 'Are you sure?',
          text: "Le champs prénom est obligatoire",
          icon: 'error',
          type: 'error'
        });

        return;
      }


      if (!this.user_name) {
        this.$swal({
          // title: 'Are you sure?',
          text: 'Veuillez presicer le nom d\'utilisateur',
          icon: 'error',
          type: 'error'
        });

        return;
      }

      if (!this.email) {
        this.$swal({
          // title: 'Are you sure?',
          text: 'L\'adresse mail est obligatoire',
          icon: 'error',
          type: 'error'
        });

        return;
      }

      if (!this.selected_role) {
        this.$swal({
          // title: 'Are you sure?',
          text: 'Veuillez preciser le niveau d\'access avant de sauvegarder',
          icon: 'error',
          type: 'error'
        });
        return;
      }


      // console.log('data', this.selected_day + " " + this.selected_month + " " + this.selected_year);


      this.saveUser();

    },

    apiLogin() {
      this.loading = true;
      window.axios.get(constants.inassapp_login_url, {
        params: {
          'username': constants.inassapp_user,
          'password': constants.inassapp_password,
        }
      })
          .then(({data}) => {
            console.log("API_RESPONSE", data);
            this.loading = false;
            if ('key' in data) {
              this.api_key = data.key;
              this.$swal({
                text: "Test réussi !!",
                icon: 'success',
                type: 'success'
              });
            } else {
              this.$swal({
                text: "Impossible de se connecter a l'API",
                icon: 'error',
                type: 'error'
              });
            }


          })
          .catch(error => {
            this.loading = false;
            console.log('LOGIN_ERROR', error.response);
            this.$swal({
              text: "Impossible de se connecter a l'API",
              icon: 'error',
              type: 'error'
            });
          });
    },


  },

  watch: {},

  // Lifecycle Hooks
  beforeCreate() {
  },

  created() {
  },

  beforeMount() {
    this.getUsers();
    this.getUser();
    this.getInstitutions();
  },

  mounted() {
    // console.log('Component mounted.');

    const that = this;
    $('.pass_change').click(function () {
      that.$modal.show('change_password');
    });

    $('#test_api').click(function () {
      that.apiLogin();
    });
  },

  beforeUpdate() {
  },

  updated() {
  },

  activated() {
  },

  deactivated() {
  },

  beforeDestroy() {
  },

  destroyed() {
  }
}
</script>

<style scoped>

</style>
