export default {
    // Login params
    // inassapp_login_url: 'http://200.113.226.83:8180/RequestQuote/RequestLogin',

    inassapp_user: 'wyzdev@nassagroup.com',
    inassapp_password: 'W1Yz$54@8jha$1',

    //
    // inassapp_search_url: '/nassa_search',
    // inassapp_search_by_number_url: '/nassa_search_by_number',
    // inassapp_login_url: '/nassa_login',
    // inassapp_benefit_url: '/nassa_benefit',
    // inassapp_users: '/users',
    // inassapp_reset_password: '/reset-password',
    // inassapp_institutions: '/institutions',
    // inassapp_get_user: '/get_user',
    // inassapp_generate_pdf: '/generate-pdf',
    // inassapp_print_history: '/print-history',
    // inassapp_user_status: '/user_status',
    // inassapp_history: '/get_history',
    // inassapp_save_history: '/save_history',
    // inassapp_user_log: '/get_logs',
    // inassapp_middleware_log: '/get_middleware_logs',
    // inassapp_send_email: '/send-mail',
    // inassapp_update_password: '/update_password',


    inassapp_reset_password: '/app/reset-password',
    inassapp_institutions: '/app/institutions',
    inassapp_search_url: '/app/nassa_search',
    inassapp_search_by_number_url: '/app/nassa_search_by_number',
    inassapp_login_url: '/app/nassa_login',
    inassapp_benefit_url: '/app/nassa_benefit',
    inassapp_print_history: '/app/print-history',
    inassapp_generate_pdf: '/app/generate-pdf',
    inassapp_users: '/app/users',
    inassapp_get_user: '/app/get_user',
    inassapp_user_status: '/app/user_status',
    inassapp_history: '/app/get_history',
    inassapp_save_history: '/app/save_history',
    inassapp_user_log: '/app/get_logs',
    inassapp_middleware_log: '/app/get_middleware_logs',
    inassapp_send_email: '/app/send-mail',
    inassapp_update_password: '/app/update_password',

}
